<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>아이디어 접수</h3>
      </div>
      <div class="card mx-xl-5 mt-3 p-3 mb-5">
        <div class="card-body">
          <form>
            <label for="writer" class="grey-text">작성자</label>
            <input type="text" id="writer" class="form-control" v-model="writer">
            <br>
            <label for="email" class="grey-text">이메일</label>
            <input type="email" id="email" class="form-control" v-model="email" disabled>
            <br>
            <label for="citizen" class="grey-text">신분</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="identity" value="citizen" id="citizen" v-model="identity"/>
              <label class="form-check-label" for="citizen"> 국민 </label>
              <input class="form-check-input" type="radio" name="identity" value="police" id="police" v-model="identity"/>
              <label class="form-check-label" for="police"> 경찰관 </label>
            </div>
            <br>
            <label for="title" class="grey-text">아이디어명</label>
            <input type="text" id="title" class="form-control" v-model="title">
            <br>
            <label for="reason" class="grey-text">제안배경</label>
            <input type="text" id="reason" class="form-control" v-model="reason">
            <br>
            <label for="necessity" class="grey-text">필요성</label>
            <input type="text" id="necessity" class="form-control" v-model="necessity">
            <br>
            <label for="purpose" class="grey-text">용도</label>
            <input type="text" id="purpose" class="form-control" v-model="purpose">
            <br>
            <label class="grey-text">상세설명</label>
            <textarea type="text" id="contents" class="form-control" rows="3" v-model="contents"></textarea>
            <br>
            <label for="effect" class="grey-text">기대효과</label>
            <input type="text" id="effect" class="form-control" v-model="effect">
            <br>
            <label class="grey-text">첨부파일1. (부가설명 자료)</label>
            <br>
            <label class="grey-text">>>> 기존 파일</label>
            <div v-if="file_name !== ''">
              <a :href="file_name"> {{ file_url }}</a>
            </div>
            <label class="grey-text">>>> 변경할 파일</label>
            <mdb-file-input
                class="file-input-doc"
                btnTitle="문서파일 선택"
                @getValue="getFileInputValue"
                icon-right="upload"
                textFieldTitle="excel / pdf / hwp "
                sm
                accept="application/vnd.ms-excel, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .hwp"
            />
            <p class="grey-text annotation">* 노트필기사진 첨부 가능</p>
            <br>
            <label class="grey-text">첨부파일2. (사진 및 그림파일)</label>
            <br>
            <label class="grey-text">>>> 기존 이미지</label>
            <div v-if="image_url !== ''">
              <img :src="image_url" alt="img" style="max-width: 100%; border: 1px solid grey">
            </div>
            <label class="grey-text">>>> 변경할 이미지</label>
            <mdb-file-input
                class="file-input-img"
                btnTitle="이미지파일 선택"
                @getValue="getImageFileInputValue"
                icon-right="upload"
                img
                :width="155"
                sm
                accept="image/gif, image/jpeg, image/png"
            />
            <br>
            <div class="text-right mt-4">
              <mdb-btn outline="success" small icon="backspace" @click="$router.go(-1)">뒤로가기</mdb-btn>
              <mdb-btn outline="primary" small icon="edit" @click="startUpload">수정</mdb-btn>
            </div>
          </form>
        </div>
      </div>
    </mdb-col>
    <Progress :isProgress="isProgress"/>
  </mdb-container>
</template>

<script>
import {mdbContainer, mdbFileInput, mdbCol, mdbBtn} from 'mdbvue';
import firebase from "firebase/app";
import Progress from "@/components/Progress";

export default {
  name: 'IdeaForm',
  components: {
    Progress,
    mdbContainer,
    mdbFileInput,
    mdbCol,
    mdbBtn,
  },
  data() {
    return {
      id: this.$route.params.id,
      contents: "",
      title: "",
      email: this.$store.state.user_email,
      writer: "",
      reason: "",
      necessity: "",
      identity: "citizen",
      purpose: "",
      effect: "",
      fileList: [],
      imageFileList: [],
      image_url: '',
      files: {},
      file_url: '',
      file_name: '',
      isProgress: false,
    };
  },
  mounted() {
    this.onFirebaseLoadData();
  },
  methods: {
    onFirebaseLoadData() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("ideaBoard");

      // 본문을 가져와
      docRef.doc(self.id).get()
          .then((querySnapshot) => {
            const _data = querySnapshot.data();
            // console.log(_data)

            if (!self.isEmpty(_data.files)) {
              self.files = Object.entries(_data.files);
              self.file_url = Object.entries(_data.files)[0][0];
              self.file_name = Object.entries(_data.files)[0][1];
            }
            self.image_url = _data.image_url;
            self.writer = _data.writer;
            self.email = _data.email;
            self.identity = _data.identity;
            self.title = _data.title;
            self.purpose = _data.purpose;
            self.necessity = _data.necessity;
            self.effect = _data.effect;
            self.contents = _data.contents;
            self.reason = _data.reason;
          });

    },

    isEmpty(param) {
      return Object.keys(param).length === 0;
    },


    getFileInputValue(val) {
      const self = this;
      self.fileList = val;
    },
    getImageFileInputValue(val) {
      const self = this;
      self.imageFileList = val;
    },
    onUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.fileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('files/' + file.name);
      const upload = savePath.put(file)

      self.files = {};
      self.file_name = file.name;
      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            self.isProgress = false;
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              // console.log('업로드된 경로는', url);
              self.file_url = url;
              self.files[self.file_name] = self.file_url

              if (self.imageFileList.length !== 0) {
                self.onImageUploadFile();
              } else {
                self.onSend();
              }
            });
          }
      );
    },

    onImageUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.imageFileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('images/' + file.name);
      const upload = savePath.put(file)

      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            self.isProgress = false;
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              // console.log('업로드된 경로는', url);
              self.image_url = url;
              self.onSend();
            });
          }
      );
    },
    startUpload() {
      const self = this;
      if (self.title === "" || self.contents === "" || self.email === "" || self.writer === "" || self.reason === "" || self.necessity === "" || self.purpose === "" || self.effect === "" || self.identity === "") {
        alert("모든 항목을 기입해 주세요")
        return;
      }

      self.isProgress = true;
      if (self.fileList.length !== 0) {
        self.onUploadFile();
      } else {
        if (self.imageFileList.length !== 0) {
          self.onImageUploadFile();
        } else {
          self.onSend();
        }
      }

    },
    async onSend() {
      const self = this;
      const db = firebase.firestore();

      const data = {
        title: self.title,
        writer: self.writer,
        email: self.email,
        reason: self.reason,
        necessity: self.necessity,
        identity: self.identity,
        purpose: self.purpose,
        effect: self.effect,
        contents: self.contents,
        views: 0,
        rating: 0,
        likes: 0,
        uid: self.$store.state.firebase_uid,
        reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      if (self.fileList.length !== 0) {
        data.files = self.files;
        await db.collection('ideaBoard')
            .doc(self.id)
            .set({files: {}}, {merge: true})
            .then(() => {
            })
            .catch((err) => {
              self.isProgress = false;
              console.log(err)
            })
      }

      if (self.imageFileList.length !== 0) {
        data.image_url = self.image_url;
      }

      db.collection('ideaBoard')
          .doc(self.id)
          .set(data, {merge: true})
          .then(() => {
            alert('문의가 수정되었습니다.');
            this.$router.push('/ideaList').catch((err) => {
              console.log(err)
              self.isProgress = false;
            })
          })
          .catch((err) => {
            self.isProgress = false;
            console.log(err)
          })
    }
  }
}
</script>

<style scoped>
.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

.file-input-doc {
  margin-top: 0;
  margin-bottom: 0;
}

.file-input-img {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  display: block;
}

.annotation {
  transform: translateX(-140px);
  font-size: 0.9rem;
}

.notice {
  overflow: auto;
  height: 250px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 10px 15px 15px;
  border: solid 1px #ced4da;
  color: #495057;
}

.notice p {
  padding-left: 15px;
}

.notice h5 {
  font-weight: bold;
}

.notice span {
  font-weight: bold;
  display: block;
  padding-bottom: 5px;
}

.form-check-label {
  color: #495057;
  padding-right: 25px;
}

.confirm {
  padding: 10px 0 35px;
  transform: translateX(10px);
}

#confirm {
  float: right;
}

</style>
